<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenService.return_one_summery_report') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <!-- year -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="year" vid="year" rules="required|min_value:1">
                  <b-form-group
                    label-for="year"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                    plain
                    v-model="search.year"
                    :options="yearList"
                    id="year"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- division -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Division Name" vid="division_id" :rules="{required: false}">
                  <b-form-group
                      label-for="division_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('globalTrans.division') }}
                      </template>
                      <v-select
                        multiple
                        plain
                        v-model="search.division_id"
                        :options="divisionList"
                        id="division_id"
                        :reduce="item => item.value"
                        label="text"
                        :placeholder="$t('globalTrans.select')"
                        :state="errors[0] ? false : (valid ? true : null)"
                        :filter-by="myFilter"
                      >
                      </v-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- district -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="District Name" vid="district_id" :rules="{required: false}">
                    <b-form-group
                        label-for="district_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.district') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.district_id"
                          :options="districtList"
                          id="district_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- citycorporation -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Upazila Name" vid="city_corporation_id" :rules="{required: false}">
                    <b-form-group
                        label-for="city_corporation_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.city_corporation') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.city_corporation_id"
                          :options="citycorporationList"
                          id="city_corporation_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- upazila -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Upazila Name" vid="upazila_id" :rules="{required: false}">
                    <b-form-group
                        label-for="upazila_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('globalTrans.upazila') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.upazila_id"
                          :options="upazilaList"
                          id="upazila_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- return type -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Return Type" vid="return_type" :rules="{required: false}">
                  <b-form-group
                      label-for="return_type"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('teaGardenConfig.garden_type') }}
                      </template>
                      <b-form-select
                      plain
                      v-model="search.return_type"
                      :options="returnTypeList"
                      id="return_type"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- valley -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 1">
                  <ValidationProvider name="Valley Name" vid="valley_id" :rules="{required: false}">
                    <b-form-group
                        label-for="valley_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('teaGardenConfig.valley_name') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.valley_id"
                          :options="valleyList"
                          id="valley_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- garden -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 1">
                  <ValidationProvider name="Garden Name" vid="garden_id" :rules="{required: false}">
                    <b-form-group
                        label-for="garden_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('teaGardenConfig.garden_name') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.garden_id"
                          :options="gardenList"
                          id="garden_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- bought leaf factory -->
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="search.return_type === 2">
                  <ValidationProvider name="City Corporation Name" vid="factory_id" :rules="{required: false}">
                    <b-form-group
                        label-for="factory_id"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('teaGardenConfig.factory_name') }}
                        </template>
                        <v-select
                          multiple
                          plain
                          v-model="search.factory_id"
                          :options="boughtLeafFactoryList"
                          id="factory_id"
                          :reduce="item => item.value"
                          label="text"
                          :placeholder="$t('globalTrans.select')"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :filter-by="myFilter"
                        >
                        </v-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                        </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
              <!-- search button -->
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <b-button size="sm" variant="primary" class="mt-20" type="submit">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>
    <b-row >
      <b-col md="12" v-if="showData">
        <body-card>
              <!-- table section start -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGardenService.return_one_summery_report') }} {{ $t('globalTrans.list') }}</h4>
              </template>
              <template v-slot:headerAction>
                <a
                    style="color: white"
                    title="Excel"
                    class="btn-add btn-warning mr-1"
                    :href="`${teaGardenServiceBaseUrl}btb/return-management/return-one-report/summery/?local=${$i18n.locale}&request_type=excel&search=${encodeURIComponent(JSON.stringify(search))}`"
                    target="_blank"
                  >
                    {{ $t('globalTrans.export_excel') }}
                </a>
                <a href="javascript:" class="btn-add" @click="pdfExport">
                   {{ $t('globalTrans.export_pdf') }}
                </a>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <b-col>
                      <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('teaGardenService.return_one_summery_report') }}
                      </list-report-head>
                    </b-col>
                  </b-row>
                  <template>
                    <div>
                      <div class="text-black mb-4 mt-2">
                        <b-row>
                          <b-col md="4">
                            {{ $t('globalTrans.year') }}: <strong>{{ search.year ? $n(search.year, { useGrouping: false }) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.division') }}: <strong>{{ search.division_id.length ? getDivisionName(search.division_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.district') }}: <strong>{{ search.district_id.length ? getDistrictName(search.district_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.city_corporation') }}: <strong>{{ search.city_corporation_id.length ? getCityCorporationName(search.city_corporation_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4">
                            {{ $t('globalTrans.upazila') }}: <strong>{{ search.upazila_id.length ? getUpazilaName(search.upazila_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type">
                            {{ $t('teaGardenConfig.garden_type') }}: <strong>{{ search.return_type ? getReturnType(search.return_type) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 1 && search.valley_id">
                            {{ $t('teaGardenConfig.valley_name') }}: <strong>{{ search.valley_id ? getValleyName(search.valley_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 1 && search.garden_id.length">
                            {{ $t('teaGardenConfig.garden_name') }}: <strong>{{ search.garden_id.length ? getGardenName(search.garden_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                          <b-col md="4" v-if="search.return_type === 2 && search.factory_id.length">
                            {{ $t('teaGardenConfig.factory_name') }}: <strong>{{ search.factory_id.length ? getFactoryName(search.factory_id) : $t('globalTrans.all') }}</strong>
                          </b-col>
                        </b-row>
                      </div>
                      <div class="table-responsive">
                        <!-- Tab - One -->
                        <b-col lg="12" xs="12" md="12">
                            <div class="text-center mb-1">{{ $i18n.locale === 'en' ? 'Section-1: Particular of land use' : 'সেকশন-১ঃ ভূমি ব্যবহারের তথ্যাদি' }}</div>
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="11">{{ $t('teaGarden.area_under_tea') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.area_under_seeds_and_nurseries') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.area_under_tea_cultivation') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+10)' : '(কলাম ৪+১০)' }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $t('teaGarden.seed_bari') }}</b-th>
                                      <b-th>{{ $t('teaGarden.seed_nurseries') }}</b-th>
                                      <b-th>{{ $t('teaGarden.clone_nurseries') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2+3)' : '(কলাম ১+২+৩)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.immature_tea') }}</b-th>
                                      <b-th>{{ $t('teaGarden.bushes_six_ten') }}</b-th>
                                      <b-th>{{ $t('teaGarden.bushes_eleven_forty') }}</b-th>
                                      <b-th>{{ $t('teaGarden.bushes_fortyone_sixty') }}</b-th>
                                      <b-th>{{ $t('teaGarden.bushes_above_sixty') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 5+6+7+8+9)' : '(কলাম ৫+৬+৭+৮+৯)' }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(1) }}</b-th>
                                      <b-th>{{ $n(2) }}</b-th>
                                      <b-th>{{ $n(3) }}</b-th>
                                      <b-th>{{ $n(4) }}</b-th>
                                      <b-th>{{ $n(5) }}</b-th>
                                      <b-th>{{ $n(6) }}</b-th>
                                      <b-th>{{ $n(7) }}</b-th>
                                      <b-th>{{ $n(8) }}</b-th>
                                      <b-th>{{ $n(9) }}</b-th>
                                      <b-th>{{ $n(10) }}</b-th>
                                      <b-th>{{ $n(11) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.main_seed_bari, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_seed_nurse, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_clone_nurse, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_sub_total_a, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_immature_tea, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_bushes_six_ten, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_bushes_eleven_forty, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_bushes_fortyone_sixty, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_bushes_above_sixty, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.main_sub_total_aa, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n((parseFloat(totalInfo.main_sub_total_a) + parseFloat(totalInfo.main_sub_total_aa)), { minimumFractionDigits: 2 }) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <!-- Tab - One - End -->

                        <!-- Tab - Two -->
                        <b-col lg="12" xs="12" md="12" class="mt-4">
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="14">{{ $t('teaGarden.area_under_other') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.rubber') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="9">{{ $t('teaGarden.forest') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 12+16+17+18+19+20+21)' : '(কলাম ১২+১৬+১৭+১৮+১৯+২০+২১)' }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.fallow_land') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.stream') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.masjid') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $t('teaGarden.bamboo') }}</b-th>
                                      <b-th>{{ $t('teaGarden.planted_forest') }}</b-th>
                                      <b-th>{{ $t('teaGarden.natural_forest') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 13+14+15)' : '(কলাম ১৩+১৪+১৫)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.sungrass') }}</b-th>
                                      <b-th>{{ $t('teaGarden.paddy_land') }}</b-th>
                                      <b-th>{{ $t('teaGarden.jackfruit') }}</b-th>
                                      <b-th>{{ $t('teaGarden.lemon') }}</b-th>
                                      <b-th>{{ $t('teaGarden.other_crop') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(12) }}</b-th>
                                      <b-th>{{ $n(13) }}</b-th>
                                      <b-th>{{ $n(14) }}</b-th>
                                      <b-th>{{ $n(15) }}</b-th>
                                      <b-th>{{ $n(16) }}</b-th>
                                      <b-th>{{ $n(17) }}</b-th>
                                      <b-th>{{ $n(18) }}</b-th>
                                      <b-th>{{ $n(19) }}</b-th>
                                      <b-th>{{ $n(20) }}</b-th>
                                      <b-th>{{ $n(21) }}</b-th>
                                      <b-th>{{ $n(22) }}</b-th>
                                      <b-th>{{ $n(23) }}</b-th>
                                      <b-th>{{ $n(24) }}</b-th>
                                      <b-th>{{ $n(25) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.tab_two_rubber, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_bamboo, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_planted_forest, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_natural_forest, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n((parseFloat(totalInfo.tab_two_bamboo) + parseFloat(totalInfo.tab_two_planted_forest) + parseFloat(totalInfo.tab_two_natural_forest)), { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_sungrass, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_paddy_land, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_jackfruit, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_lemon, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_other_crop, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n((parseFloat(totalInfo.tab_two_sub_total_b) - (parseFloat(totalInfo.tab_two_bamboo) + parseFloat(totalInfo.tab_two_planted_forest) + parseFloat(totalInfo.tab_two_natural_forest))), { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_fallow_land, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_stream, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_masjid, { minimumFractionDigits: 2 }) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                        <!-- Tab - Three - End -->

                        <!-- Tab - Three - start -->
                        <b-col lg="12" xs="12" md="12" class="mt-4">
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="6">{{ $t('teaGarden.other_non_agriculture') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ currentLocale == 'en' ? 'Total Area of Grant' : 'বাগানের ইজারাকৃত মোট অনুমোদিত এলাকা' }}<br> {{currentLocale == 'en' ? '(Col. 11+22+31)' : '(কলাম ১১+২২+৩১)' }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.leased_date') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.tax_date') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('globalTrans.remarks') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $t('teaGarden.graveyard') }}</b-th>
                                      <b-th>{{ $t('teaGarden.labor_line') }}</b-th>
                                      <b-th>{{ $t('teaGarden.factory') }}</b-th>
                                      <b-th>{{ $t('teaGarden.school') }}</b-th>
                                      <b-th>{{ $t('teaGarden.sect_road') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 23+24+25+26+27+28+29+30)' : '(কলাম ২৩+২৪+২৫+২৬+২৭+২৮+২৯+৩০)' }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(26) }}</b-th>
                                      <b-th>{{ $n(27) }}</b-th>
                                      <b-th>{{ $n(28) }}</b-th>
                                      <b-th>{{ $n(29) }}</b-th>
                                      <b-th>{{ $n(30) }}</b-th>
                                      <b-th>{{ $n(31) }}</b-th>
                                      <b-th>{{ $n(32) }}</b-th>
                                      <b-th>{{ $n(33) }}</b-th>
                                      <b-th>{{ $n(34) }}</b-th>
                                      <b-th>{{ $n(35) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.tab_two_graveyard, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_labor_line, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_factory, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_school, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_sect_road, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_two_sub_total_bb, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(((parseFloat(totalInfo.main_sub_total_a) + parseFloat(totalInfo.main_sub_total_aa)) + (parseFloat(totalInfo.tab_two_sub_total_b) - (parseFloat(totalInfo.tab_two_bamboo) + parseFloat(totalInfo.tab_two_planted_forest) + parseFloat(totalInfo.tab_two_natural_forest))) + parseFloat(totalInfo.tab_two_sub_total_bb)), { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td></b-td>
                                      <b-td></b-td>
                                      <b-td></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>

                        <!-- Section - 2  -->
                        <b-col lg="12" xs="12" md="12" class="mt-4">
                            <div class="text-center mb-1">{{ $i18n.locale === 'en' ? 'Section-2: Extension Activities During the Season' : 'সেকশন-২ঃ চা বাগানের সম্প্রসারণ কাজসমূহ'}}</div>
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.plantation_added') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.plantation_decreased') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.scope_extension') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $t('teaGarden.extension') }}</b-th>
                                      <b-th>{{ $t('teaGarden.replanting') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2)' : '(কলাম ১+২)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.abandoned_area') }}</b-th>
                                      <b-th>{{ $t('teaGarden.uprooted_area') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.ava_area') }}</b-th>
                                      <b-th>{{ $t('teaGarden.area_no') }}</b-th>
                                      <b-th>{{ $t('teaGarden.extension_scope') }}</b-th>
                                      <b-th>{{ $t('globalTrans.remarks') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(1) }}</b-th>
                                      <b-th>{{ $n(2) }}</b-th>
                                      <b-th>{{ $n(3) }}</b-th>
                                      <b-th>{{ $n(4) }}</b-th>
                                      <b-th>{{ $n(5) }}</b-th>
                                      <b-th>{{ $n(6) }}</b-th>
                                      <b-th>{{ $n(7) }}</b-th>
                                      <b-th>{{ $n(8) }}</b-th>
                                      <b-th>{{ $n(9) }}</b-th>
                                      <b-th>{{ $n(10) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.tab_three_extension, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_replanting, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_sub_total_c, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_abandoned_area, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_uprooted_area, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_sub_total_cc, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_ava_area, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_area_no, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_three_extension_scope, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>

                        <!-- Section - 3  -->
                        <b-col lg="12" xs="12" md="12" class="mt-4">
                            <div class="text-center mb-1">{{ $i18n.locale === 'en' ? 'Section-3: Extension Activities During the Season' : 'সেকশন-৩ঃ চা বীজ ও গাছ সম্পর্কিত তথ্যাদি'}}</div>
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center" colspan="10">{{ $t('teaGarden.tea_seeds') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2" colspan="3">{{ $t('teaGarden.tea_plants') }}</b-th>
                                    </b-tr>
                                    <b-tr>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.produced') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.used') }}</b-th>
                                      <b-th class="align-middle text-center" rowspan="2">{{ $t('teaGarden.surplus') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="3">{{ $t('teaGarden.disposal_surplus') }}</b-th>
                                      <b-th class="align-middle text-center" colspan="4">{{ $t('teaGarden.deficit_met') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $t('teaGarden.sold') }}</b-th>
                                      <b-th>{{ $t('teaGarden.disposed_otherwise') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.purchased') }}</b-th>
                                      <b-th>{{ $t('teaGarden.conn_otherwise') }}</b-th>
                                      <b-th>{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 7+8)' : '(কলাম ৭+৮)' }}</b-th>
                                      <b-th>{{ $t('teaGarden.estim_requirement') }}</b-th>
                                      <b-th>{{ $t('teaGarden.area_season') }}</b-th>
                                      <b-th>{{ $t('teaGarden.plants_infilled') }}</b-th>
                                      <b-th>{{ $t('teaGarden.avg_plants') }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(1) }}</b-th>
                                      <b-th>{{ $n(2) }}</b-th>
                                      <b-th>{{ $n(3) }}</b-th>
                                      <b-th>{{ $n(4) }}</b-th>
                                      <b-th>{{ $n(5) }}</b-th>
                                      <b-th>{{ $n(6) }}</b-th>
                                      <b-th>{{ $n(7) }}</b-th>
                                      <b-th>{{ $n(8) }}</b-th>
                                      <b-th>{{ $n(9) }}</b-th>
                                      <b-th>{{ $n(10) }}</b-th>
                                      <b-th>{{ $n(11) }}</b-th>
                                      <b-th>{{ $n(12) }}</b-th>
                                      <b-th>{{ $n(13) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.tab_four_produced, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_used, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_surplus, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_sold, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_disposed_otherwise, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_sub_total_d, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_purchased, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_conn_otherwise, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_sub_total_dd, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_estim_requirement, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_area_season, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_plants_infilled, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_four_avg_plants, { minimumFractionDigits: 2 }) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>

                        <!-- Section - 4  -->
                        <b-col lg="12" xs="12" md="12" class="mt-4">
                            <div class="text-center mb-1">{{ $i18n.locale === 'en' ? 'Section-4: Tea Waste' : 'সেকশন-৪ঃ চা জাবরা'}}</div>
                            <b-table-simple bordered style="font-size: 14px">
                                <b-thead>
                                    <b-tr>
                                      <b-th class="align-middle text-center">{{ $t('teaGarden.stock_inhand') }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('teaGarden.collection') }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 1+2)' : '(কলাম ১+২)' }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('teaGarden.qty_disposed') }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('teaGarden.qty_destroyed') }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('globalTrans.total') }}<br> {{currentLocale == 'en' ? '(Col. 4+5)' : '(কলাম ৪+৫)' }}</b-th>
                                      <b-th class="align-middle text-center">{{ $t('teaGarden.balance') }}<br> {{currentLocale == 'en' ? '(Col. 3-6)' : '(কলাম ৩-৬)' }}</b-th>
                                    </b-tr>
                                    <b-tr class="text-center align-middle">
                                      <b-th>{{ $n(1) }}</b-th>
                                      <b-th>{{ $n(2) }}</b-th>
                                      <b-th>{{ $n(3) }}</b-th>
                                      <b-th>{{ $n(4) }}</b-th>
                                      <b-th>{{ $n(5) }}</b-th>
                                      <b-th>{{ $n(6) }}</b-th>
                                      <b-th>{{ $n(7) }}</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr class="text-center align-middle">
                                      <b-td>{{ $n(totalInfo.tab_five_stock_inhand, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_five_collection, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_five_sub_total_e, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_five_qty_disposed, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_five_qty_destroyed, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n(totalInfo.tab_five_sub_total_ee, { minimumFractionDigits: 2 }) }}</b-td>
                                      <b-td>{{ $n((totalInfo.tab_five_sub_total_e - totalInfo.tab_five_sub_total_ee), { minimumFractionDigits: 2 }) }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                      </div>
                    </div>
                  </template>
                </b-overlay>
              </template>
              <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnOneSummeryReportApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import excel from 'vue-excel-export'
import { helpers } from '@/utils/helper-functions'
import Vue from 'vue'
import { ValidationObserver } from 'vee-validate'
Vue.use(excel)
export default {
  mixins: [ModalBaseMasterList],
  components: {
  ListReportHead,
      ValidationObserver
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      valid: null,
      search: {
        division_id: [],
        district_id: [],
        upazila_id: [],
        city_corporation_id: [],
        return_type: 0,
        valley_id: [],
        garden_id: [],
        factory_id: [],
        year: new Date().getFullYear(),
        month: 0
      },
      datas: [],
      totalInfo: {},
      showData: false,
      districtList: [],
      upazilaList: [],
      gardenList: [],
      boughtLeafFactoryList: [],
      citycorporationList: []
    }
  },
  created () {
    this.getGardenList()
    this.getBoughtLeafFactoryList()
  },
  computed: {
      divisionList () {
        return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
      },
      valleyList () {
        return this.$store.state.TeaGardenService.commonObj.masterValleyList.filter(item => item.status === 1)
      },
      monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
          return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
        })
      },
      yearList () {
        return helpers.getYearList()
      },
      returnTypeList () {
        return this.$store.state.TeaGardenService.commonObj.returnTypeList.map(item => {
          return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
        })
      },
      currentLocale () {
          return this.$i18n.locale
      }
  },
  methods: {
    // call for searching dropdown
    myFilter: (option, text, search) => {
      const temp = search.toLowerCase()
      return option.text_en.toLowerCase().indexOf(temp) > -1 ||
      option.text_bn.toLowerCase().indexOf(temp) > -1
    },
    districtRowSpan (checkData) {
      let total = 0
      checkData.forEach((element, key) => {
        element.garden_fact_info.forEach((element2, key2) => {
          total++
        })
      })
      return total
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, returnOneSummeryReportApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.showData = true
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, returnOneSummeryReportApi, params)
      if (result.success) {
        this.datas = result.data
        this.totalInfo = result.totalInfo
        this.$store.dispatch('setList', this.datas)
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        }
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDivisionName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.CommonService.commonObj.divisionList.find(item => item.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getGardenName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getValleyName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.TeaGardenService.commonObj.masterValleyList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.TeaGardenService.commonObj.masterValleyList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getDistrictName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.CommonService.commonObj.districtList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.CommonService.commonObj.districtList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getUpazilaName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.CommonService.commonObj.upazilaList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.CommonService.commonObj.upazilaList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getCityCorporationName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.CommonService.commonObj.cityCorporationList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.CommonService.commonObj.cityCorporationList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getFactoryName (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      } else {
        const data = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(data => data.value === ids)
        return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
      }
    },
    getDistrictList (divisionIds) {
      this.districtList = this.$store.state.CommonService.commonObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })

      if (this.search.district_id.length) {
        const arr = []
        this.search.district_id.forEach(id => {
          const obj = this.districtList.find(obj => obj.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(id)
          }
        })
        this.search.district_id = arr
      }
    },
    getCityCorporationList (districtIds) {
      this.citycorporationList = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => districtIds.includes(item.district_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })

      if (this.search.city_corporation_id.length) {
        const arr = []
        this.search.city_corporation_id.forEach(id => {
          const obj = this.citycorporationList.find(obj => obj.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(id)
          }
        })
        this.search.city_corporation_id = arr
      }
    },
    getUpazilaList (districtIds) {
      this.upazilaList = this.$store.state.CommonService.commonObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
            return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })

      if (this.search.upazila_id.length) {
        const arr = []
        this.search.upazila_id.forEach(id => {
          const obj = this.upazilaList.find(obj => obj.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(id)
          }
        })
        this.search.upazila_id = arr
      }
    },
    getReturnType (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.returnTypeList.find(item => item.value === id)
      return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenList () {
        this.gardenList = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        // search in division
        if (this.search.division_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.division_id.includes(item.division_id))
        }
        // search in district
        if (this.search.district_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.district_id.includes(item.district_id))
        }
        // search in upazila
        if (this.search.upazila_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.upazila_id.includes(item.upazila_id))
        }
        // search in city corporation
        if (this.search.city_corporation_id.length) {
          this.gardenList = this.gardenList.filter(item => this.search.city_corporation_id.includes(item.city_corporation_id))
        }
        // search in valley id
        if (this.search.valley_id) {
          this.gardenList = this.gardenList.filter(item => this.search.valley_id.includes(item.valley_id))
        }

        if (this.search.garden_id.length) {
          const arr = []
          this.search.garden_id.forEach(id => {
            const obj = this.gardenList.find(obj => obj.value === parseInt(id))
            if (obj !== undefined) {
              arr.push(id)
            }
          })
          this.search.garden_id = arr
        }
    },
    getBoughtLeafFactoryList () {
      this.boughtLeafFactoryList = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(item => item.status === 1 && item.factory_type_id === 2)
      // search in division
      if (this.search.division_id.length) {
        this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.division_id.includes(item.division_id))
      }
      // search in district
      if (this.search.district_id.length) {
        this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.district_id.includes(item.district_id))
      }
      // search in upazila
      if (this.search.upazila_id.length) {
        this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.upazila_id.includes(item.upazila_id))
      }
      // search in city corporation
      if (this.search.city_corporation_id.length) {
        this.boughtLeafFactoryList = this.boughtLeafFactoryList.filter(item => this.search.city_corporation_id.includes(item.city_corporation_id))
      }

      if (this.search.factory_id.length) {
        const arr = []
        this.search.factory_id.forEach(id => {
          const obj = this.boughtLeafFactoryList.find(obj => obj.value === parseInt(id))
          if (obj !== undefined) {
            arr.push(id)
          }
        })
        this.search.factory_id = arr
      }
    }
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      },
      'search.division_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getDistrictList(newVal)
          this.getBoughtLeafFactoryList()
          this.getGardenList()
        } else {
          this.districtList = []
        }
      },
      'search.district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getUpazilaList(newVal)
          this.getCityCorporationList(newVal)
          this.getBoughtLeafFactoryList()
          this.getGardenList()
        } else {
          this.upazilaList = []
          this.citycorporationList = []
        }
      },
      'search.upazila_id': function (newVal, oldVal) {
          this.getBoughtLeafFactoryList()
          this.getGardenList()
      },
      'search.city_corporation_id': function (newVal, oldVal) {
          this.getBoughtLeafFactoryList()
          this.getGardenList()
      },
      'search.valley_id': function (newVal, oldVal) {
        this.getGardenList()
      },
      currentLocale: function (newVal, oldVal) {
        if (this.search.division_id.length) {
          this.getDistrictList(this.search.division_id)
        }

        if (this.search.district_id.length) {
          this.getUpazilaList(this.search.district_id)
          this.getCityCorporationList(this.search.district_id)
        }

        if (this.search.valley_id) {
          this.getGardenList()
        }
      }
  }
}
</script>
<style scoped>
.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table-bordered tbody th, .table-bordered tbody td {
    border-bottom-width: 1px !important;
    border-color: #333 !important;
}
.table th, .table td {
    padding: 0.25rem !important;
}

.font-bn .table-wrapper table thead tr th {
    font-size: 1rem;
}
</style>
